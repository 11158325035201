import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { WorkspaceService } from '../_services';
import { v4 as uuid } from 'uuid';
import { Entity, EntityType } from '../_model';
import Point from 'ol/geom/Point.js';

export interface Annotation {
  longitude: number;
  latitude: number;
}

@Component({
  selector: 'app-add-annotation',
  templateUrl: './add-annotation.component.html',
  styleUrls: ['./add-annotation.component.css']
})
export class AddAnnotationComponent implements OnInit {

  icons: string[] = [
    'home',
    'sighting'
  ];

  entity: Entity = {
    type: EntityType.annotation,
    id: uuid(),
    longitude: null,
    latitude: null,
    data: null,
    icon: null,
    name: null,
    displayOptions: {}
  };

  easting: number;
  northing: number;
  coordType = 'bng';

  constructor(
    public dialogRef: MatDialogRef<AddAnnotationComponent>,
    @Inject(MAT_DIALOG_DATA) public iconInfo: Annotation,
    private workspaceService: WorkspaceService
  ) {
    this.entity.longitude = +iconInfo.longitude.toFixed(4);
    this.entity.latitude = +iconInfo.latitude.toFixed(4);
    [this.easting, this.northing] = this.transform([iconInfo.longitude, iconInfo.latitude], 'EPSG:4326', 'EPSG:27700')
        .map(coord => Math.round(coord));
  }

  ngOnInit() {
  }

  close(): void {
    this.dialogRef.close();
  }

  addAnnotation() {
    if (this.coordType === 'bng') {
      // create the lon / lat
      [this.entity.longitude, this.entity.latitude] = this.transform([this.easting, this.northing], 'EPSG:27700', 'EPSG:4326');
    }
    this.workspaceService.addAnnotation(this.entity);
    this.dialogRef.close();
  }

  private transform(coords: number[], from: string, to: string): number[] {
    return new Point(coords).transform(from, to).getCoordinates();
  }
}
