import { Component, OnInit } from '@angular/core';
import { Injectable } from '@angular/core';
import OlMap from 'ol/Map';
import OlXYZ from 'ol/source/XYZ';
import OlTileLayer from 'ol/layer/Tile';
import OlView from 'ol/View';
import { get as getProjection } from 'ol/proj';
import { register } from 'ol/proj/proj4.js';
import proj4 from 'proj4';
import TileWMS from 'ol/source/TileWMS.js';
import TileImage from 'ol/source/TileImage.js';
import Overlay from 'ol/Overlay.js';
import { Style, Icon, Text, Fill, Stroke } from 'ol/style.js';
import { Vector as VectorSource } from 'ol/source.js';
import Feature from 'ol/Feature.js';
import { Vector as VectorLayer } from 'ol/layer.js';
import { MiddlemanService, WorkspaceService, DataService } from '../_services';
import Point from 'ol/geom/Point.js';
import Polygon from 'ol/geom/Polygon';
import ContextMenu from 'ol-contextmenu';
import { MatDialog } from '@angular/material';
import { AddAnnotationComponent } from '../add-annotation/add-annotation.component';
import { EntityType, Entity } from '../_model';
import WMTSTileGrid from 'ol/tilegrid/WMTS';
import {Image as ImageLayer} from 'ol/layer.js';
import ImageWMS from 'ol/source/ImageWMS.js';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})

@Injectable({
  providedIn: 'root'
})
export class MapComponent implements OnInit {

  map: OlMap;
  source: OlXYZ;
  view: OlView;
  selectedPopupEntity: Entity;
  hoveredEntity: Entity;

  backdropLayer: OlTileLayer;

  rfSurveyLayers: any = {};
  theoreticalCoverageLayer: ImageLayer;
  cellAzimuthLayer: ImageLayer;
  cellPopup: Overlay;

  radiusLayer: VectorLayer;
  entitiesLayer: VectorLayer;
  entityLabelsLayer: VectorLayer;
  selectedSearchLayer: VectorLayer;

  private rfColours = [
    'c00000',
    'ff0000',
    'ffc000',
    'ffff00',
    '92d050',
    '00b050',
    '00b0f0',
    '0070c0',
    '002060',
    '7030a0'
  ];

  // Define our icons style eg. the image, the size and the anchor points
  cellStyle = new Style({
    image: new Icon( /** @type {olx.style.IconOptions} */({
      anchor: [0.5, 0.5],
      anchorXUnits: 'fraction',
      anchorYUnits: 'fraction',
      scale: 1.2,
      src: './assets/images/entity-icons/cell.svg'
    }))
  });

  contextMenu: ContextMenu;

  radiusStyle = new Style({
    fill: new Fill({
      color: 'rgba(192,192,192,0.25)'
    }),
    stroke: new Stroke({
      color: 'blue',
      width: 2
    }),
    text: new Text({
      textAlign: 'Start',
      textBaseline: 'Middle',
      font: 'Normal 12px Arial',
      text: 'Approximate Area',
      fill: new Fill({
        color: '#ffa500'
      }),
      stroke: new Stroke({
        color: '#000000',
        width: 3
      }),
      offsetX: -45,
      offsetY: 0,
      rotation: 0
    })
  });

  resolutions = [1120, 560, 280, 140, 70, 28, 14, 7, 2.8, 1.4];

  // Subscribe to the middleman.service and wait for a cell to be selected
  constructor(
    private middlemanService: MiddlemanService,
    private workspaceService: WorkspaceService,
    public dialog: MatDialog,
    public dataService: DataService
  ) { }

  ngOnInit() {

    // On map load, Using proj4 to convert our custom EPSG:27700 projection
    proj4.defs('EPSG:27700', '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 ' +
      '+x_0=400000 +y_0=-100000 +ellps=airy ' +
      '+towgs84=446.448,-125.157,542.06,0.15,0.247,0.842,-20.489 ' +
      '+units=m +no_defs');
    register(proj4);

    // Variable to hold our custom projection
    const proj27700 = getProjection('EPSG:27700');

    // Set the extent(View) of the current projection
    proj27700.setExtent([0, 0, 700000, 1300000]);

    this.radiusLayer = new VectorLayer({
      source: new VectorSource({}),
      style: this.radiusStyle
    });

    // Define our 'Search Layer' which only shows the currently searched for icons
    this.selectedSearchLayer = new VectorLayer({
      source: new VectorSource({}),
      style: this.cellStyle,
      renderBuffer: 200
    });

    this.entitiesLayer = new VectorLayer({
      source: new VectorSource({}),
      style: this.getEntityStyleFunction()
    });

    this.entityLabelsLayer = new VectorLayer({
      source: new VectorSource({}),
      style: this.getEntityLabelStyleFunction()
    });

    // Initialise the backdrop with the defaults from the workspace service
    this.backdropLayer = new OlTileLayer({
      source: new TileWMS({
        url: '/geoserver/gwc/service/wms',
        // url: '/geoserver/cmaps/wms',
        params: {
          LAYERS: this.workspaceService.backdrop,
          VERSION: '1.1.0'
        },
        serverType: 'geoserver',
        transition: 0,
        // Set the grid to match the GeoServer / GeoWebCache gridset settings
        tileGrid      : new WMTSTileGrid({
          origin      : [0, 0],
          tileSize    : [256, 256],
          resolutions : this.resolutions,
          maxExtent   : [0, 0, 700000, 13000000]
        })
      }),
      projection: 'EPSG:27700' // data source projection,
    });

    // Create a OL layer for the watermark. We do this client-side rather that using GeoServer as there is no way to set
    //  geoserver watermarks per layer
    const watermark = new OlTileLayer({
      preload: 1,
      opacity: 0.9,
      source: new TileImage({
        crossOrigin: null,
        url: 'assets/images/cmaps_watermark.png',
        // Set the grid to match the GeoServer / GeoWebCache gridset settings
        tileGrid      : new WMTSTileGrid({
          origin      : [0, 0],
          tileSize    : [400, 400],
          resolutions : this.resolutions,
          maxExtent   : [0, 0, 700000, 13000000]
        })
      })
    });

    // Defines the theoretical coverage layer from geoserver
    this.theoreticalCoverageLayer = this.createWmsLayer('cmaps:cells_theoretical_coverage', 'cells_theoretical_coverage');
    // Defines the azimuth layer from geoserver
    this.cellAzimuthLayer = this.createWmsLayer('cmaps:cells', 'cells_azimuth');

    // Define all our layers to add to the map
    const layers = [
      this.backdropLayer,
      this.theoreticalCoverageLayer,
      this.cellAzimuthLayer,
      this.radiusLayer,
      this.entitiesLayer,
      this.entityLabelsLayer,
      this.selectedSearchLayer,
      watermark
    ];

    this.backdropLayer.setZIndex(0);
    // Leave index 1 for RF layers
    this.theoreticalCoverageLayer.setZIndex(2);
    this.cellAzimuthLayer.setZIndex(3);
    this.radiusLayer.setZIndex(4);
    this.entitiesLayer.setZIndex(5);
    this.entityLabelsLayer.setZIndex(6);
    this.selectedSearchLayer.setZIndex(7);
    watermark.setZIndex(8);


    // Initialise the optional layers as not visible
    this.theoreticalCoverageLayer.setOpacity(0);
    this.cellAzimuthLayer.setOpacity(0);

    // Set the current views properties, Including using our custom projection, extent and zoom levels
    this.view = new OlView({
      resolutions: this.resolutions,
      extent: [0, 0, 700000, 1300000],
      projection: 'EPSG:27700',
      center: [507459, 175692],
      zoom: 7
    });

    // Put the layers and the views ontop of the map
    this.map = new OlMap({
      target: 'map',
      layers: layers,
      view: this.view,
      // Fix the pixel ratio to prevent OL from being too clever with DPIs (using the wrong DPI will mean we can't use GeoWebCache!)
      pixelRatio: 1
    });

    // Define the div of our popup
    const element = document.getElementById('popup');

    // Define the popup with its positioning and offsets.
    this.cellPopup = new Overlay({
      element: element,
      positioning: 'bottom-center',
      stopEvent: false,
      offset: [0, 0]
    });
    // Add the popup to the current (And only) map
    this.map.addOverlay(this.cellPopup);

    this.initMapClick();
    this.initMapHover();

    this.map.updateSize();

    this.subscribeToCellStatusUpdates();
    this.subscribeToPanToEntity();
    this.subscribeToEntityAdded();
    this.subscribeToEntityDeleted();
    this.subscribeToSearchStarted();
    this.subscribeToSearchCompleted();
    this.subscribeToBackdropChanged();

    this.initContextMenu();
    this.subscribeToSearchUEvents();

    // Update the workspace service each time the map view is changed. That way we know the current extent of the map
    // when we need to print, or load the workspace from a saved state.
    const self = this;
    this.view.on('change', function(evt) {
      self.updateMapExtent();
    });

    // Do the first update
    this.updateMapExtent();

    // Load the entities that have already been added
    this.initEntities();
  }

  private updateMapExtent() {
    this.workspaceService.updateMapExtent(this.view.calculateExtent());
  }

  private featureFromEntity(entity: Entity) {
    const bngPoint = new Point([entity.longitude, entity.latitude]).transform('EPSG:4326', 'EPSG:27700');

    return new Feature({
      // Each property of the selected cell is mapped onto a feature array
      geometry: bngPoint,
      id: entity.id,
      // Put the entire celldata through
      entity: entity
    });
  }

  /**
   * Create a new WMS layer.
   *
   * @param layerName the name of the WMS layer
   * @param styleName the namme of the style to use
   * @param env the SLD variable substitution values to use
   */
  private createWmsLayer(layerName: string, styleName: string, env: any = {}) {
    let envParam = null;
    if (env && Object.keys(env).length) {
      envParam = Object.keys(env).map(envKey => `${envKey}:${env[envKey]}`).join(';');
    }
    return new ImageLayer({
      source: new ImageWMS({
        url: '/geoserver/cmaps/wms',
        params: { LAYERS: layerName, STYLES: styleName, env: envParam },
        serverType: 'geoserver',
      }),
      projection: 'EPSG:27700' // data source projection
    });
  }

  /**
   * Create a style function for entities
   */
  getEntityStyleFunction() {
    return function(feature) {
      return new Style({
        image: new Icon({
          anchor: [0.5, 0.5],
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction',
          scale: 1.2,
          src: `./assets/images/entity-icons/${feature.get('entity').icon}.svg`
        })
      });
    };
  }

  /**
   * Create a style function for entities
   */
  getEntityLabelStyleFunction() {
    return function(feature) {
      return new Style({
        text: new Text({
          font: 'bold 13px Calibri,sans-serif',
          fill: new Fill({
            color: '#000'
          }),
          stroke: new Stroke({
            color: '#fff',
            width: 4
          }),
          text: feature.get('entity').name,
          offsetY: 16
        })
      });
    };
  }

  /**
   * Create a context menu for adding annotations
   */
  initContextMenu() {
    const self = this;
    this.contextMenu = new ContextMenu({
      width: 170,
      defaultItems: false,
      items: [
        {
          text: 'Add Annotation',
          callback: function (contextData) {
            self.showAddAnnotationDialog(contextData.coordinate);
          }
        },
        {
          text: 'Search Here',
          callback: function (contextData) {
            self.middlemanService.triggerSearchByLocation({
              // Pass through the coordinates rounded to the nearest metre
              coordinates: contextData.coordinate.map(value => Math.round(value))
            });
          }
        }
      ]
    });
    this.map.addControl(this.contextMenu);
  }

  showAddAnnotationDialog(coordinate) {

    const lonLatPoint = new Point(coordinate).transform('EPSG:27700', 'EPSG:4326');

    const dialogRef = this.dialog.open(AddAnnotationComponent, {
      width: '250px',
      data: { longitude: lonLatPoint.getCoordinates()[0], latitude: lonLatPoint.getCoordinates()[1] },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  initMapClick() {
    const self = this;
    // Click event for the searched features on the map
    self.map.on('click', function (evt) {
      // Close the context menu if it is open
      self.contextMenu.close();

      let featureFound;
      self.map.forEachFeatureAtPixel(evt.pixel, function (ft, layer) {
        if (layer === self.entitiesLayer || layer === self.selectedSearchLayer) {
          featureFound = ft;
        }
      });
      if (featureFound && featureFound.get('entity').type === EntityType.cell) {
        self.showCellPopup(featureFound.get('entity'));
      } else {
        self.closeCellPopup();
      }
    });
  }

  initMapHover() {
    const self = this;
    // Hover event for when the user hovers over a cell
    self.map.on('pointermove', function (evt) {
      let featureFound;
      self.map.forEachFeatureAtPixel(evt.pixel, function (ft, layer) {
        if (layer === self.entitiesLayer || layer === self.selectedSearchLayer) {
          featureFound = ft;
        }
      });
      if (featureFound && featureFound.get('entity').type === EntityType.cell) {
        self.hoveredEntity = featureFound.get('entity');
      } else {
        self.hoveredEntity = null;
      }
    });
  }

  drawSearchRadius(coordinates, radius) {
    this.radiusLayer.getSource().clear();

    const circleCoords = this.createCirclePointCoords(coordinates[0], coordinates[1], radius, 60);

    const geom = new Polygon([
      circleCoords
    ]);

    this.radiusLayer.getSource().addFeature(new Feature({
      geometry: geom,
      name: 'Polygon'
    }));

    this.radiusLayer.getSource().refresh();
  }

  createCirclePointCoords(circleCenterX, circleCenterY, circleRadius, pointsToFind) {
    const angleToAdd = 360 / pointsToFind;
    const coords = [];
    let angle = 0;
    for (let i = 0; i < pointsToFind; i++) {
      angle = angle + angleToAdd;
      const coordX = circleCenterX + circleRadius * Math.cos(angle * Math.PI / 180);
      const coordY = circleCenterY + circleRadius * Math.sin(angle * Math.PI / 180);
      coords.push([coordX, coordY]);
    }
    return coords;
  }

  subscribeToBackdropChanged() {
    this.middlemanService.backdropChanged$.subscribe(backdrop => {
      if (backdrop) {
        // Update the layer CQL filter and refresh
        const params = this.backdropLayer.getSource().getParams();
        params.LAYERS = backdrop;
        this.backdropLayer.getSource().updateParams(params);

        // Show layer
        this.backdropLayer.setVisible(true);
      } else {
        this.backdropLayer.setVisible(false);
      }
    });
  }

  /**
   * Subscribe to pan to entity events so that we can trigger the map zoom pan and display popup if necessary
   */
  private subscribeToPanToEntity() {
    this.middlemanService.panToEntity$.subscribe(entity => {
      // TODO: it's a bit wasteful to create a feature when all we need are the coordinates
      const feature = this.featureFromEntity(entity);

      console.log(`Panning to ${feature.getGeometry().getCoordinates()}`);

      // Tell the view to pan over to that cells co-ords
      this.view.animate({
        center: feature.getGeometry().getCoordinates(),
        // zoom: 8,
        duration: 1000
      });

      if (entity.type === EntityType.cell) {
        this.showCellPopup(entity);
      }
    });
  }

  subscribeToCellStatusUpdates() {
    // TODO: might be worth merging all of these into a single event
    // Subscribe to changes to theoretical coverage visibility
    this.middlemanService.cellTheoreticalCoverageUpdated$.subscribe(
      cellData => {
        this.updateTheoreticalCoverage();
      });

    // Subscribe to changes to to rf survey visibility
    this.middlemanService.cellRfSurveyUpdated$.subscribe(
      cellData => {
        this.updateRfSurvey();
      });

    // Subscribe to changes to cell azimuth visibility
    this.middlemanService.cellAzimuthUpdated$.subscribe(
      cellData => {
        this.updateAzimuth();
      });
  }

  subscribeToSearchCompleted() {
    this.middlemanService.searchCompleteDataSource.subscribe(event => {
      console.log('Search result changed');
      this.selectedSearchLayer.getSource().clear();
      // Convert the entities into features
      const resultFeatures = event.results.map(entity => this.featureFromEntity(entity));
      // Add the features to the map
      this.selectedSearchLayer.getSource().addFeatures(resultFeatures);

      if (event.type === 'coordinates') {
        this.drawSearchRadius(event.coordinates, event.radius);
      } else {
        this.radiusLayer.getSource().clear();
      }
    });
  }

  /**
   * Add the subscription to the search started event. When triggered, this will cause the search radius to be displayed on the map
   * and the existing map results to be cleared.
   */
  subscribeToSearchStarted() {
    this.middlemanService.searchStartedDataSource.subscribe(event => {
      console.log('Clearing search');
      this.selectedSearchLayer.getSource().clear();

      if (event.type === 'coordinates') {
        this.drawSearchRadius(event.coordinates, event.radius);
      } else {
        this.radiusLayer.getSource().clear();
      }
    });
  }

  private subscribeToEntityAdded() {
    const self = this;
    this.middlemanService.entityAddedToWorkspace$.subscribe(entity => {
      console.log(`Adding cell entity to map - ${entity.id}`);

      self.addEntity(entity);
    });
  }

  /**
   * Subscribe to entity deleted events so we can remove the entities from the map
   */
  private subscribeToEntityDeleted() {
    const self = this;
    this.middlemanService.entityDeletedFromWorkspace$.subscribe(entity => {
      console.log(`Deleting entity from map - ${entity.id}`);

      self.deleteEntity(entity);
    });
  }

  /**
   * Initialise the map to include the entities that already exist in the workspace. This is required for when we
   * navigate away from the map page and back again.
   */
  private initEntities() {
    // Load the entities from the workspace service
    this.workspaceService.cells.forEach(entity => this.addEntity(entity));
    this.workspaceService.annotations.forEach(annotation => this.addEntity(annotation));

    // Display azimuth, rf calls or coverage if they are already enabled for existing cells
    this.updateAzimuth();
    this.updateRfSurvey();
    this.updateTheoreticalCoverage();
  }

  private addEntity(entity: Entity) {
    const bngPoint = new Point([entity.longitude, entity.latitude]).transform('EPSG:4326', 'EPSG:27700');

    // Create a feature for the cell
    const feature = new Feature({
      // Each property of the selected cell is mapped onto a feature array
      geometry: bngPoint,
      // id: entity.id,
      // Keep a reference to the entity on the feature
      entity: entity
    });
    feature.setId(entity.id);

    this.entitiesLayer.getSource().addFeature(feature);

    // If it is an annotation, show a label
    if (entity.type === EntityType.annotation) {
      this.entityLabelsLayer.getSource().addFeature(feature);
    }

    // Init the azimuth, RF calls and theoretical coverage for the new entity
    this.updateAzimuth();
    this.updateRfSurvey();
    this.updateTheoreticalCoverage();
  }

  /**
   * Delete the entity from the map
   * @param entity the entity to delete
   */
  private deleteEntity(entity: Entity) {
    // Find the feature for the entity
    const feature = this.entitiesLayer.getSource().getFeatureById(entity.id);
    if (feature) {
      this.entitiesLayer.getSource().removeFeature(feature);
    }

    // Check if there is a label feature and delete it
    const labelFeature = this.entityLabelsLayer.getSource().getFeatureById(entity.id);
    if (labelFeature) {
      this.entityLabelsLayer.getSource().removeFeature(labelFeature);
    }

    // Hide the popup if it is attached to an entity that is not in the workspace
    if (this.selectedPopupEntity && !this.workspaceService.hasCell(this.selectedPopupEntity)) {
      this.closeCellPopup();
    }

    this.updateAzimuth();
    this.updateRfSurvey();
    this.updateTheoreticalCoverage();
  }

  // If the window is resized, give the map a prod to update its bounds
  onResized(event) {
    this.map.updateSize();
  }

  updateTheoreticalCoverage() {
    // Find the entities that should be displayed
    const matchingEntities = this.workspaceService.cells.filter(entity => entity.displayOptions.showTheoreticalCoverage);
    console.log(`Updating theoretical coverages - ${matchingEntities.length} to update`);
    // Update the CQL
    this.updateLayerCellReferenceCql(this.theoreticalCoverageLayer, matchingEntities);
  }

  /**
   * Update the visiblity of RF survey data. Each entities RF survey coverage is displayed as a separate layer. This allows us to set a different
   * colour per entity using SLD variable substitution
   */
  updateRfSurvey() {
    // Find the entities that should be added or removed from the map
    const matchingEntities = this.workspaceService.cells.filter(entity => entity.displayOptions.showRfSurvey);
    const nonMatchingEntities = this.workspaceService.cells.filter(entity => !entity.displayOptions.showRfSurvey);

    // Create a new layer for each of the RF entities that are newly selected
    const entitiesToAdd = matchingEntities.filter(entity => !Object.keys(this.rfSurveyLayers).includes(entity.id));
    entitiesToAdd.forEach(entity => {
      // Use the cells index to determine it's colour
      const colourIndex = this.workspaceService.cells.indexOf(entity) % this.rfColours.length;
      const colour = this.rfColours[colourIndex];
      const entityRfLayer = this.createWmsLayer('cmaps:rf_calls', 'rf_calls', { color: colour});
      entityRfLayer.setZIndex(1);
      this.updateLayerCgiCql(entityRfLayer, [entity]);
      this.map.addLayer(entityRfLayer);
      this.rfSurveyLayers[entity.id] = entityRfLayer;
    });

    // Remove the layers for each of the RF entities that are no longer selected
    const entitiesToRemove = nonMatchingEntities.filter(entity => Object.keys(this.rfSurveyLayers).includes(entity.id));
    entitiesToRemove.forEach(entity => {
      const entityRfLayer = this.rfSurveyLayers[entity.id];
      this.map.removeLayer(entityRfLayer);
      delete this.rfSurveyLayers[entity.id];
    });

    // Remove the layers for each of the RF entities that are deleted
    const entitiesDeleted = Object.keys(this.rfSurveyLayers).filter(entityId => !this.workspaceService.findEntityById(entityId));
    entitiesDeleted.forEach(entityId => {
      const entityRfLayer = this.rfSurveyLayers[entityId];
      this.map.removeLayer(entityRfLayer);
      delete this.rfSurveyLayers[entityId];
    });
  }

  updateAzimuth() {
    // Find the entities that should be displayed
    const matchingEntities = this.workspaceService.cells.filter(entity =>  {
      // Only show azimuth for cells which have a bearing
      return entity.displayOptions.showAzimuth && entity.data.properties.bearing_deg != null;
    });
    // Update the CQL
    this.updateLayerCellReferenceCql(this.cellAzimuthLayer, matchingEntities);
  }

  /**
   * Update the CQL filter for the given layer to include the cell references for the given entities.
   */
  updateLayerCellReferenceCql(layer, entitiesToDisplay) {
    if (!entitiesToDisplay.length) {
      // Update the layer CQL filter to hide the layer. This fixes issue CUR2B-6.
      const params = layer.getSource().getParams();
      params.CQL_FILTER = 'true = false';
      layer.getSource().updateParams(params);

      // Set the opacity to 0 otherwise there is a short delay before the layer is hidden
      layer.setOpacity(0);
    } else {
      // Get the update CQL filter
      const filter = entitiesToDisplay.map(entity => `cell_reference = '${entity.id}'`).join(' OR ');

      // Update the layer CQL filter and refresh
      const params = layer.getSource().getParams();
      params.CQL_FILTER = `live = true AND (${filter})`;
      layer.getSource().updateParams(params);

      // Show layer
      layer.setOpacity(1);
    }
  }

  /**
   * Update the CQL filter for the given layer to include the CGIs for the given entities.
   */
  updateLayerCgiCql(layer, entitiesToDisplay) {
    if (!entitiesToDisplay.length) {
      // Update the layer CQL filter to hide the layer. This fixes issue CUR2B-6.
      const params = layer.getSource().getParams();
      params.CQL_FILTER = 'true = false';
      layer.getSource().updateParams(params);

      // Set the opacity to 0 otherwise there is a short delay before the layer is hidden
      layer.setOpacity(0);
    } else {
      // Get the update CQL filter
      const filter = entitiesToDisplay.map(entity => `cgi = '${entity.data.properties.cgi}'`).join(' OR ');

      // Update the layer CQL filter and refresh
      const params = layer.getSource().getParams();
      params.CQL_FILTER = `live = true AND (${filter})`;
      layer.getSource().updateParams(params);

      // Show layer
      layer.setOpacity(1);
    }
  }

  subscribeToSearchUEvents() {
    this.middlemanService.searchOpened$.subscribe(() => {
      // Show the search-related layers when search is opened
      this.radiusLayer.setVisible(true);
      this.selectedSearchLayer.setVisible(true);
    });

    this.middlemanService.searchClosed$.subscribe(() => {
      // Hide the search-related layers when the search is closed
      this.radiusLayer.setVisible(false);
      this.selectedSearchLayer.setVisible(false);

      // Hide the popup if it is attached to an entity that is not in the workspace
      if (this.selectedPopupEntity && !this.workspaceService.hasCell(this.selectedPopupEntity)) {
        this.closeCellPopup();
      }
    });
  }

  /**
   * Display a map popup for the given cell entity
   * @param entity the entity to display the popup for
   */
  showCellPopup(entity: Entity) {
    this.selectedPopupEntity = entity;
    const bngPoint = new Point([entity.longitude, entity.latitude]).transform('EPSG:4326', 'EPSG:27700');
    this.cellPopup.setPosition(bngPoint.getCoordinates());
  }

  /**
   * Close the cell popup
   */
  closeCellPopup() {
    this.selectedPopupEntity = undefined;
    this.cellPopup.setPosition(undefined);
  }
}

