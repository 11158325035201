import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-session-expired-dialog',
  templateUrl: './session-expired-dialog.component.html',
  styleUrls: ['./session-expired-dialog.component.css']
})
export class SessionExpiredDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<SessionExpiredDialogComponent>, private router: Router) { }

  ngOnInit() {
  }

  login() {
    // Go to the login page
    this.router.navigate(['/login'], { queryParams: { returnUrl: 'map' }});
    // Close this dialog
    this.dialogRef.close();
  }

}
