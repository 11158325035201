import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { DataService } from '../../_services';
import { switchMap } from 'rxjs/operators';
import { interval, Subscription } from 'rxjs';

/**
 * Angular component which allows the user to select a file and upload it. The component also polls for updates of the last upload.
 */
@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css']
})
export class UploadComponent implements OnInit, OnDestroy {

  file: File;
  fileName: string;
  uploadStatus: any;
  uploadInProgress = false;
  // Have to make this publicly available so we can unsubscribe in the unit tests to prevent the "periodic timers still in queue" errors
  pollingSubscription: Subscription;

  @ViewChild('fileInputElem') fileInputElem: ElementRef;

  constructor(
    private dataService: DataService
  ) {}

  ngOnInit() {
    this.pollingSubscription = interval(2000).pipe(
      switchMap(() => this.dataService.getStatus())
    ).subscribe((result) => {
      this.uploadStatus = result;
    });
  }

  ngOnDestroy() {
    this.pollingSubscription.unsubscribe();
  }

  /**
   * Upload the currently selected file.
   */
  uploadFile() {
    this.uploadInProgress = true;

    this.dataService.upload(this.file).pipe(
      switchMap(() => this.dataService.getStatus())
    ).subscribe((result) => {
      this.uploadStatus = result;

      this.uploadInProgress = false;
      // Clear the value directly on the element, otherwise we cannot reselect the same file as it thinks that nothing has changed
      this.fileInputElem.nativeElement.value = '';
      this.file = null;
      this.fileName = null;
    }, err => {
      this.uploadInProgress = false;
    });
  }

  /**
   * Handle a file selection change event by storing the newly selected file as a variable on this component.
   * @param event the change event
   */
  fileChange(event: any): void {
    const fileList = event.target.files;
    this.file = fileList.length > 0 ? fileList[0] : null;
    this.fileName = this.file ? this.file.name : '';
  }

}
