import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Entity, SearchCompleteEvent, SearchByLocationEvent } from '../_model';
import { SearchStartedEvent } from '../_model/search-started-event';


@Injectable({
  providedIn: 'root'
})
export class MiddlemanService {

  public triggerSearchByLocationDataSource = new Subject<SearchByLocationEvent>();
  public triggerSearchByLocation$ = this.triggerSearchByLocationDataSource.asObservable();

  public panToEntityDataSource = new Subject<Entity>();
  public panToEntity$ = this.panToEntityDataSource.asObservable();

  public searchStartedDataSource = new Subject<SearchStartedEvent>();
  public searchStarted$ = this.searchStartedDataSource.asObservable();
  public searchCompleteDataSource = new Subject<SearchCompleteEvent>();
  public searchComplete$ = this.searchCompleteDataSource.asObservable();

  // Workspace updates
  // TODO: these should probably be moved to the Workspace Service

  // Entity Added
  public entityAddedToWorkspaceSource = new Subject<Entity>();
  public entityAddedToWorkspace$ = this.entityAddedToWorkspaceSource.asObservable();
  // Entity Deleted
  public entityDeletedFromWorkspaceSource = new Subject<Entity>();
  public entityDeletedFromWorkspace$ = this.entityDeletedFromWorkspaceSource.asObservable();
  // Backdrop Changed
  public backdropChangedSource = new Subject<string>();
  public backdropChanged$ = this.backdropChangedSource.asObservable();

  // Entity updates
  public cellTheoreticalCoverageUpdatedSource = new Subject<any>();
  public cellTheoreticalCoverageUpdated$ = this.cellTheoreticalCoverageUpdatedSource.asObservable();

  public cellRfSurveyUpdatedSource = new Subject<any>();
  public cellRfSurveyUpdated$ = this.cellRfSurveyUpdatedSource.asObservable();

  public cellAzimuthUpdatedSource = new Subject<any>();
  public cellAzimuthUpdated$ = this.cellAzimuthUpdatedSource.asObservable();

  // UI Events
  public searchOpenedSource = new Subject();
  public searchOpened$ = this.searchOpenedSource.asObservable();
  public searchClosedSource = new Subject();
  public searchClosed$ = this.searchClosedSource.asObservable();

  /**
   * Trigger a new search by location. This will cause a new search to be carried out with given criteria.
   * @param event the search event
   */
  triggerSearchByLocation(event: SearchByLocationEvent) {
    this.triggerSearchByLocationDataSource.next(event);
  }

  /**
   * Notify other components that a search item has been selected
   * @param cell the search item
   */
  panToEntity(cellData: Entity) {
    this.panToEntityDataSource.next(cellData);
  }

  /**
   * Notify other components that an entity has been added to the workspace
   * @param entity the added entity
   */
  entityAddedToWorkspace(entity: Entity) {
    this.entityAddedToWorkspaceSource.next(entity);
  }

  /**
   * Notify other components that an entity has been deleted from the workspace
   * @param entity the entity that was deleted
   */
  entityDeletedFromWorkspace(entity: Entity) {
    this.entityDeletedFromWorkspaceSource.next(entity);
  }

  /**
   * Notify other components that the backdrop is being changed
   * @param backdrop the new of the backdrop map being changed to
   */
  backdropChanged(backdrop: string) {
    this.backdropChangedSource.next(backdrop);
  }

  /**
   * Notify other components that a cells coverage display has been toggled
   * @param cell the updated cell
   */
  cellTheoreticalCoverageUpdated(cell) {
    this.cellTheoreticalCoverageUpdatedSource.next(cell);
  }

  /**
   * Notify other components that a cells RF survey display has been toggled
   * @param cell the updated cell
   */
  cellRfSurveyUpdated(cell) {
    this.cellRfSurveyUpdatedSource.next(cell);
  }

  /**
   * Notify other components that a cells azimuth display has been toggled
   * @param cell the updated cell
   */
  cellAzimuthUpdated(cell) {
    this.cellAzimuthUpdatedSource.next(cell);
  }

  /**
   * Notify components that a new search has been started
   */
  searchStarted(searchStartedEvent: SearchStartedEvent) {
    this.searchStartedDataSource.next(searchStartedEvent);
  }

  /**
   * Notify other components that a search has completed
   * @param searchCompleteEvent search results
   */
  searchComplete(searchCompleteEvent: SearchCompleteEvent) {
    this.searchCompleteDataSource.next(searchCompleteEvent);
  }

  /**
   * Notify other components when the search control is opened / displayed.
   */
  searchOpened() {
    this.searchOpenedSource.next();
  }

  searchClosed() {
    this.searchClosedSource.next();
  }

}
