import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from '../_services';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdminAuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {

    // Check if the current user is an admin
    if (!this.authService.isAdminUser()) {
      return of(false);
    }

    // Call the check_token endpoint to ensure that the current token is valid
    return this.authService.checkToken().pipe(
      map(auth => true),
      catchError(err => {
        // If an error is throw then the token is invalid. In this case return the user to the login page
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        return of(false);
      })
    );
  }
}
