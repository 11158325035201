import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CellService, WorkspaceService } from '../_services';
import { fromEvent ,  Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { MiddlemanService } from '../_services';
import { debounceTime } from 'rxjs/operators';
import { Entity, SearchFilter, SearchResult, SearchResults } from '../_model';
import Point from 'ol/geom/Point';

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  coords: Entity;
  subscription: Subscription;
  searchTerm = '';
  isSearching = false;
  showRadius = false;
  searchRadius = 10000;
  coordinateType: string;
  searchCoordinates: number[];
  filter: SearchFilter = {
    csp: 'all',
    generation: 'all'
  };
  searchSubscription: Subscription;

  @ViewChild('searchTermElement') searchTermElement: ElementRef;

  searchResults = [];
  exportResultsUrl: string;

  constructor(
    private cellService: CellService,
    private middlemanService: MiddlemanService,
    private workspaceService: WorkspaceService
  ) {

  }

  ngOnInit() {
    // Reduce the debounce time to make the search feel more responsive, Will this be ok for GeoServer
    fromEvent(this.searchTermElement.nativeElement, 'keyup').pipe(
      debounceTime(100)
    ).subscribe((text: string) => this.search());

    // Listen for when the location search is triggered externally (e.g. map context option)
    this.middlemanService.triggerSearchByLocation$.subscribe(event => {
      console.log(`Search triggered externally with coordinates ${event.coordinates}`);
      // Update the search term
      this.searchTerm = event.coordinates.join(',');
      // Trigger the search
      this.search();
    });
  }

  search() {
    this.searchCoordinates = null;
    // Only search if the searchterm has been trimmed of whitespace and its value still equals one or more chars
    if (this.searchTerm.trim().length >= 1) {
      /* The Regex below is testing if the searchTerm the user entered is similar to a co-ordinate
      This logic decides if the user is searching for a coord or by cell reference */
      if (/^(\-?\d+(\.\d+)?),\s*(\-?\d+(\.\d+)?)$/.test(this.searchTerm)) {
        this.showRadius = true;
        this.searchByCoordinates();
      } else {
        this.showRadius = false;
        this.searchByReference();
      }
    } else {
      // Still need to notify any listeners that the search results have changed
      this.showRadius = false;
      this.searchResults = [];
      this.middlemanService.searchComplete({
        type: 'reference',
        results: [],
        coordinates: null,
        radius: null
      });
    }
  }

  /**
   * Search for cells by cell reference. The cell reference is retrieved from the searchTerm field which bound to the search input.
   */
  searchByReference() {
    this.isSearching = true;
    this.middlemanService.searchStarted({
      type: 'reference',
      coordinates: null,
      radius: null
    });
    this.searchResults = [];

    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }

    this.searchSubscription = this.cellService.searchByReference(this.searchTerm, this.filter).subscribe(
      (results: SearchResults) => {
        this.exportResultsUrl = results.exportUrl;
        this.searchResults = results.results;
        this.isSearching = false;
        this.middlemanService.searchComplete({
          type: 'reference',
          results: results.results.map(result => result.entity),
          coordinates: null,
          radius: null
        });
      });
  }

  /**
   * Search for cells by location. The location is parsed from the search term which is expected to be of the form lon,lat or
   * easting,northing. E.g. "-1.241,52.123" or "35223,46622".
   */
  searchByCoordinates() {
    // Parse the search term into coordinates and radius
    const formattedSearchTerm = this.searchTerm.replace(/ /g, '').toUpperCase().replace(/'/g, '');
    const coordinates = formattedSearchTerm.split(',').map(coord => parseFloat(coord));

    this.searchCoordinates = coordinates;
    this.coordinateType = 'BNG';

    if (coordinates[0] < 90 && coordinates[1] < 180) {
      this.coordinateType = 'lon/lat';
      // coord - bng will just carry on
      this.searchCoordinates = new Point(coordinates).transform('EPSG:4326', 'EPSG:27700').getCoordinates();
    }

    // TODO: trigger an event to notify that a search has been started. This will allow the map to update the draw radius earlier
    this.middlemanService.searchStarted({
      type: 'coordinates',
      coordinates: this.searchCoordinates,
      radius: this.searchRadius
    });

    this.searchResults = [];

    this.isSearching = true;

    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }

    this.searchSubscription = this.cellService.searchByCoordinates(this.searchCoordinates, this.searchRadius, this.filter).subscribe(
      (results: SearchResults) => {
        this.exportResultsUrl = results.exportUrl;
        this.searchResults = results.results.sort((a, b) => a.distance - b.distance);
        this.isSearching = false;
        this.middlemanService.searchComplete({
          type: 'coordinates',
          results: results.results.map(result => result.entity),
          coordinates: this.searchCoordinates,
          radius: this.searchRadius
        });
      });
  }

  /**
   * Notify other componenents that the user has selected the search item so that they can respond
   * appropriately. E.g. so the map can display a popup
   * @param searchResult the search result to select
   */
  selectSearchItem(searchResult: SearchResult) {
    this.middlemanService.panToEntity(searchResult.entity);
  }

  /**
   * Add the given search result's entity to the workspace
   * @param searchResult the search result to add an entity for
   */
  addEntityToWorkspace(searchResult: SearchResult) {
    this.workspaceService.addCell(searchResult.entity);
  }
}
