import { Component, OnInit, Input } from '@angular/core';
import { MiddlemanService } from '../_services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css']
})

export class CellPopupComponent implements OnInit {

  @Input() entity: any;
  subscription: Subscription;
  popdata: any;

  constructor(private _Middleman: MiddlemanService) {
    this.subscription = _Middleman.panToEntity$.subscribe(
      cellData => {
        // Give the popup all the cell data from the cell that was selected
        this.popdata = cellData;
      });
  }

  ngOnInit() {

  }

}
