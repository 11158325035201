import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from '../_services';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    // Check if there is a token present. If there is retrieve it and set it on the auth service.
    // This does not store the token in local storage.
    const token = next.queryParams.token;
    if (token) {
      this.authService.clearSession();
      this.authService.jwtString = token;
      this.router.navigate([next.routeConfig.path], {replaceUrl: true});
      return false;
    }

    // Call the check_token endpoint to ensure that the current token is valid
    return this.authService.checkToken().pipe(
      map(auth => true),
      catchError(err => {
        // If an error is throw then the token is invalid then logour and redirect the user
        this.authService.logout();
        return of(false);
      })
    );
  }
}
