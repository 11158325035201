import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Subscription } from 'rxjs';

import { MiddlemanService, WorkspaceService } from '../_services';
import { Entity, EntityType } from '../_model';


@Component({
  selector: 'app-entities',
  templateUrl: './entities.component.html',
  styleUrls: ['./entities.component.css']
})
export class EntitiesComponent implements OnInit {
  // Make enum available to template
  public EntityType = EntityType;

  subscription: Subscription;

  constructor(
    private middlemanService: MiddlemanService,
    public workspaceService: WorkspaceService,
    public snackBar: MatSnackBar
  ) {}

  ngOnInit() {
  }

  toggleTheoreticalCoverage(cell: Entity) {
    cell.displayOptions.showTheoreticalCoverage = !cell.displayOptions.showTheoreticalCoverage;
    // Notify other components of the change
    this.middlemanService.cellTheoreticalCoverageUpdated(cell);
  }

  toggleRfSurvey(cell: Entity) {
    cell.displayOptions.showRfSurvey = !cell.displayOptions.showRfSurvey;
    // Notify other components of the change
    this.middlemanService.cellRfSurveyUpdated(cell);
  }

  toggleAzimuth(cell: Entity) {
    cell.displayOptions.showAzimuth = !cell.displayOptions.showAzimuth;
    // Notify other components of the change
    this.middlemanService.cellAzimuthUpdated(cell);
  }

  /**
   * Delete the entity from the workspace
   * @param entity the entity to delete
   */
  deleteEntity(entity: Entity) {
    this.workspaceService.deleteEntity(entity);
    // Open a snack bar to allow the user to undo
    const snackBarRef = this.snackBar.open(`Entity ${entity.id} deleted`, 'Undo', {
      duration: 5000
    });

    // Listen for the undo click
    snackBarRef.onAction().subscribe(() => {
      if (entity.type === EntityType.annotation) {
        this.workspaceService.addAnnotation(entity);
      } else if (entity.type === EntityType.cell) {
        this.workspaceService.addCell(entity);
      }
    });
  }

  panToEntity(entity: Entity) {
    this.middlemanService.panToEntity(entity);
  }
}
