import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AuthService } from '../_services';

@Component({
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  returnUrl: string;
  error = '';
  inProgress: boolean;
  progressMode = 'determinate';
  progressValue = 0;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    // reset login status
    this.authService.clearSession();

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.error = null;
    this.inProgress = true;
    this.authService.login(this.f.username.value, this.f.password.value)
      .subscribe(
        data => {
          this.inProgress = false;
          this.router.navigate([this.returnUrl]);
        },
        error => {
          this.inProgress = false;
          if (error.status === 400) {
            this.error = 'Invalid username or password';
          } else {
            this.error = error.statusText;
          }
        });
  }
}
