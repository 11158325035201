import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login';
import { MainComponent } from './main';
import { AuthGuard } from './_guards';
import { AdminAuthGuard } from './_guards';
import { UploadComponent } from './admin/upload';

const appRoutes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'main', component: MainComponent, canActivate: [AuthGuard] },
  { path: 'upload', component: UploadComponent, canActivate: [AdminAuthGuard] },
  // otherwise redirect to home
  { path: '**', redirectTo: 'main' }
];

export const routing = RouterModule.forRoot(appRoutes);
