import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import { environment } from '../../environments/environment';

/**
 * Angular service for handling user authentication.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _jwtString: string;
  private _jwtDecoded: any;

  constructor(private http: HttpClient) {
    this._jwtString = localStorage.getItem('jwtString');
  }

  /**
   * Getter for jwtString.
   */
  public get jwtString(): string {
    return this._jwtString;
  }

  /**
   * Setter for jwtString. Also updates the value stored in localStorage.
   */
  public set jwtString(value: string) {
    this._jwtString = value;
    if (value === null || value === undefined) {
      localStorage.removeItem('jwtString');
    } else {
      localStorage.setItem('jwtString', value);
    }
  }

  /**
   * Getter for jwtDecoded.
   */
  public get jwtDecoded(): any {
    return this._jwtDecoded;
  }

  /**
   * Log in with the given username and password. If the login request succeeds, the user token is stored in local storage
   * to be used in all subsequent requests.
   *
   * @param username the username
   * @param password the password
   */
  login(username: string, password: string): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('grant_type', 'password');
    formData.append('username', username);
    formData.append('password', password);

    return this.http.post<any>('/oauth/token', formData)
      .pipe(
        map((res: any) => {
        // login successful if there's a jwt token in the response
        if (res && res.access_token) {
          this.jwtString = res.access_token;
        }
      }));
  }

  /**
   * Check that the current auth token is valid. Called the "check_token" Spring security endpoint.
   */
  checkToken(): Observable<any> {
    if (!this.jwtString) {
      return throwError('No user currently logged in');
    }
    return this.http.get('/oauth/check_token', { params: { token: this.jwtString }}).pipe(
      tap(jwtDecoded => this._jwtDecoded = jwtDecoded)
    );
  }

  /**
   * Log the current user out by clearning the user from local storage.
   */
  logout(): void {
    this.clearSession();
    // Redirect the user back to CDS
    window.location.href = environment.logoutRedirectUrl;
  }

  /**
   * Clear the current user and session information.
   */
  clearSession(): void {
    localStorage.removeItem('jwtString');
    this.jwtString = null;
    this._jwtDecoded = null;
  }

  /**
   * Check if the currently logged in user is an admin user. This does not check the token for validity.
   */
  isAdminUser(): boolean {
    return this._jwtDecoded && this._jwtDecoded.authorities.includes('ADMIN_USER');
  }
}
