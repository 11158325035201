import { Pipe, PipeTransform } from '@angular/core';

/**
 * Angular pipe for converting all underscores in a string into spaces
 */
@Pipe({
  name: 'underscoresToSpaces'
})
export class UnderscoresToSpacesPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return value.replace(/_/g, ' ');
  }

}
