import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';

/**
 * Angular service for data uploads and status monitoring.
 */
@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private http: HttpClient) { }

  /**
   * Upload the given file.
   * @param upload the file to upload
   */
  public upload(upload: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', upload, upload.name);

    // Request headers
    const uploadHeaders = new HttpHeaders();
    uploadHeaders.append('Accept', 'application/json');
    uploadHeaders.append('enctype', 'multipart/form-data');

    return this.http.post('/api/data', formData, { headers: uploadHeaders });
  }

  /**
   * Get the status for the currently uploading, or last uploaded file.
   */
  public getStatus(): Observable<any> {
    return this.http.get('/api/data/status');
  }
}
