import { Injectable } from '@angular/core';
import { MiddlemanService } from './middleman.service';
import { Entity, EntityType } from '../_model';
import { CellService } from './cell.service';
import { combineLatest } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WorkspaceService {

  public cells: Entity[] = [];
  public annotations: Entity[] = [];

  public mapExtent: number[];

  // Default to OSM as Northern Ireland users do not have OS maps
  public backdrop = 'cmaps:backdrop_osm';

  constructor(
    private middlemanService: MiddlemanService,
    private cellService: CellService
  ) { }

  addCell(entity: Entity) {
    console.log(`Adding entity ${entity.id} to workspace`);

    // Check if entity is already in list
    if (!this.hasCell(entity)) {
      const hasRfData$ = this.cellService.cellHasRfData(entity.data.properties.cgi);
      const hasTheoreticalCoverage$ = this.cellService.cellHasTheoreticalCoverage(entity.data.properties.cell_reference);

      // Add the new entity
      this.cells.push(entity);

      // Trigger an event to notify other components
      this.middlemanService.entityAddedToWorkspace(entity);

      // Determine whether the entity has RF data and theoretical coverage
      combineLatest(hasRfData$, hasTheoreticalCoverage$)
        .subscribe(([hasRf, hasTheoreticalCoverage]) => {
          entity.displayOptions.hasRfSurvey = hasRf;
          entity.displayOptions.hasTheoreticalCoverage = hasTheoreticalCoverage;
        });
    }
  }

  /**
   * Determine whether the given entity already exists in the workspace
   * @param entity the entity to search for
   */
  hasCell(entity: Entity): boolean {
    return entity ? this.cells.some((existingEntity) => existingEntity.id === entity.id) : false;
  }

  /**
   * Find the workspace entity matching the given id.
   * @param entityId the entity if it exist, undefined otherwise
   */
  findEntityById(entityId: string): Entity {
    return this.cells.find(entity => entity.id === entityId);
  }

  addAnnotation(entity: Entity) {
    console.log(`Adding entity ${entity.id} to workspace`);

    // Check if entity is already in list
    if (!this.annotations.some((existingEntity) => existingEntity.id === entity.id)) {
      // Item doesn't exist in list

      // Add the new entity
      this.annotations.push(entity);

      // Trigger an event to notify other components
      this.middlemanService.entityAddedToWorkspace(entity);
    }
  }

  setBackdrop(backdrop: string) {
    this.backdrop = backdrop;
    // Notify other components that the backdrop has been changed
    this.middlemanService.backdropChanged(backdrop);
  }

  updateMapExtent(extent: number[]) {
    this.mapExtent = extent;
  }

  /**
   * Delete the entity from the workspace
   * @param entity the entity to delete
   */
  deleteEntity(entity: Entity) {
    if (entity.type === EntityType.annotation) {
      this.annotations = this.annotations.filter(annotationEntity => annotationEntity.id !== entity.id);
    } else if (entity.type === EntityType.cell) {
      this.cells = this.cells.filter(cellEntity => cellEntity.id !== entity.id);
    }
    // Notify other components that the entity has been deleted
    this.middlemanService.entityDeletedFromWorkspace(entity);
  }
}
