import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularResizedEventModule } from 'angular-resize-event';
import { routing } from './app.routing';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { MapComponent } from './map/map.component';

// custom material module that includes all of the Material components we need
import { MaterialModule } from './material/material.module';
import { MainComponent } from './main/main.component';
import { SearchComponent } from './search/search.component';
import { MiddlemanService } from './_services';
import { UploadComponent } from './admin/upload/upload.component';
import { UnderscoresToSpacesPipe } from './_pipes/underscores-to-spaces.pipe';
import { JwtInterceptor } from './_helpers/jwt.interceptor';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { EntitiesComponent } from './entities/entities.component';
import { CellPopupComponent } from './popup/popup.component';
import { RouterModule } from '@angular/router';
import { AddAnnotationComponent } from './add-annotation/add-annotation.component';
import { PrintDialogComponent } from './print-dialog/print-dialog.component';
import { SessionExpiredDialogComponent } from './session-expired-dialog/session-expired-dialog.component';
import { LogoutDialogComponent } from './logout-dialog/logout-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MapComponent,
    MainComponent,
    SearchComponent,
    UploadComponent,
    UnderscoresToSpacesPipe,
    EntitiesComponent,
    CellPopupComponent,
    AddAnnotationComponent,
    PrintDialogComponent,
    SessionExpiredDialogComponent,
    LogoutDialogComponent
  ],
  imports: [
    AngularResizedEventModule,
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    routing,
    FormsModule
  ],
  providers: [
    MiddlemanService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    AddAnnotationComponent,
    PrintDialogComponent,
    SessionExpiredDialogComponent,
    LogoutDialogComponent
  ]

})
export class AppModule { }
