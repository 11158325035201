import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { MiddlemanService } from './middleman.service';
import Point from 'ol/geom/Point';
import { register } from 'ol/proj/proj4.js';
import proj4 from 'proj4';
import { Entity, EntityType, SearchFilter, SearchResults } from '../_model';
import { Observable } from 'rxjs';
import { get as getProjection } from 'ol/proj';


@Injectable({
  providedIn: 'root'
})
export class CellService {
  url = '/geoserver/cmaps/ows';

  private maxSearchResults = 2000;

  constructor(private http: HttpClient, private middleman: MiddlemanService) {
    proj4.defs('EPSG:27700', '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 ' +
      '+x_0=400000 +y_0=-100000 +ellps=airy ' +
      '+towgs84=446.448,-125.157,542.06,0.15,0.247,0.842,-20.489 ' +
      '+units=m +no_defs');
    register(proj4);

    // Variable to hold our custom projection
    const proj27700 = getProjection('EPSG:27700');

    // Set the extent(View) of the current projection
    proj27700.setExtent([0, 0, 700000, 1300000]);
  }

  /**
   * Determine whether the cell with the given CGI has any RF data.
   */
  cellHasRfData(cgi: string): Observable<boolean> {
    const params = {
      service: 'WFS',
      version: '1.0.0',
      request: 'GetFeature',
      typeName: 'cmaps:rf_calls',
      maxFeatures: '1',
      outputFormat: 'application/json',
      CQL_FILTER: `cgi = '${cgi}'`
    };

    return this.http.get(this.url, { params }).pipe(
      map((featureCollection: any) => {
        return featureCollection.totalFeatures > 0;
      })
    );
  }

  /**
   * Determine whether the cell with the given CGI has any RF data.
   */
  cellHasTheoreticalCoverage(cellReference: string): Observable<boolean> {
    const params = {
      service: 'WFS',
      version: '1.0.0',
      request: 'GetFeature',
      typeName: 'cmaps:cells_theoretical_coverage',
      maxFeatures: '1',
      outputFormat: 'application/json',
      CQL_FILTER: `cell_reference = '${cellReference}'`
    };

    return this.http.get(this.url, { params }).pipe(
      map((featureCollection: any) => {
        return featureCollection.totalFeatures > 0;
      })
    );
  }

  /* Send request to geo server with params
  Filter CQLFilter to make string uppercase, Remove spaces and remove apostrophe's */
  searchByReference(searchTerm: string, filter: SearchFilter): Observable<SearchResults> {
    const formattedSearchTerm = searchTerm.replace(/ /g, '').toUpperCase().replace(/'/g, '');
    const filterParts = [
      'live = true',
      `search_string like '%${formattedSearchTerm}%'`
    ];
    if (filter.csp !== 'all') {
      filterParts.push(`strToLowerCase(carrier) = '${filter.csp}'`);
    }
    if (filter.generation !== 'all') {
      filterParts.push(`strToLowerCase(generation) = '${filter.generation}'`);
    }

    const params = {
      service: 'WFS',
      version: '1.0.0',
      request: 'GetFeature',
      typeName: 'cmaps:cells',
      maxFeatures: `${this.maxSearchResults}`,
      outputFormat: 'application/json',
      CQL_FILTER: filterParts.join(' AND ')
    };

    return this.http.get(this.url, { params }).pipe(
      map((featureCollection: any) => {
        return featureCollection.features.map(feature => {
          // Convert the features into SearchResults
          return {
            entity: this.entityFromGeoJSON(feature)
          };
        });
      }),
      // Wrap the results in a SearchResults object
      map((results) => {
        return {
          results,
          exportUrl: this.getExportUrl(params)
        };
      })
    );
  }

  getExportUrl(params) {
    const exportParams = Object.assign({}, params);
    exportParams.outputFormat = 'csv';
    const queryString = Object.keys(exportParams).map((key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(exportParams[key]);
    }).join('&');
    return `${this.url}?${queryString}`;
  }

  searchByCoordinates(searchCoordinates: number[], radius: number, filter: SearchFilter): Observable<SearchResults> {
    const filterParts = [
      'live = true',
      `DWITHIN(geom,Point(${searchCoordinates[0]} ${searchCoordinates[1]}),${radius},meters)`
    ];
    if (filter.csp !== 'all') {
      filterParts.push(`strToLowerCase(carrier) = '${filter.csp}'`);
    }
    if (filter.generation !== 'all') {
      filterParts.push(`strToLowerCase(generation) = '${filter.generation}'`);
    }

    const params = {
      service: 'WFS',
      version: '1.0.0',
      request: 'GetFeature',
      typeName: 'cmaps:cells',
      maxFeatures: `${this.maxSearchResults}`,
      outputFormat: 'application/json',
      CQL_FILTER: filterParts.join(' AND ')
    };

    return this.http.get(this.url, { params }).pipe(
      // Convert the features into SearchResults
      map((featureCollection: any) => {
        return featureCollection.features.map(feature => {
          const entity = this.entityFromGeoJSON(feature);
          return {
            entity,
            distance: this.getDistance(feature.geometry.coordinates, searchCoordinates)
          };
        });
      }),
      // Wrap the results in a SearchResults object
      map((results) => {
        return {
          results,
          exportUrl: this.getExportUrl(params)
        };
      })
    );
  }

  getDistance(from, to) {
    return Math.hypot(to[0] - from[0], to[1] - from[1]);
  }

  entityFromGeoJSON(geoJson): Entity {
    const lonLatPoint = new Point(geoJson.geometry.coordinates).transform('EPSG:27700', 'EPSG:4326');
    return {
      type: EntityType.cell,
      data: geoJson,
      id: geoJson.properties.cell_reference,
      longitude: lonLatPoint.getCoordinates()[0],
      latitude: lonLatPoint.getCoordinates()[1],
      icon: 'cell',
      name: geoJson.properties.cell_reference,
      displayOptions: {}
    };
  }
}
