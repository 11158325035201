import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PrintRequest } from '../_model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PrintService {

  constructor(private http: HttpClient) { }

  public print(printRequest: PrintRequest): Observable<any> {
    return this.http.post('/api/print', printRequest, {
      responseType: 'blob',
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }
}
