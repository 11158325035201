import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material';
import { FormGroup, FormControl } from '@angular/forms';
import { PrintRequest } from '../_model';
import { WorkspaceService } from '../_services';
import { PrintService } from '../_services/print.service';
import { saveAs } from 'file-saver/FileSaver';
import { SessionExpiredDialogComponent } from '../session-expired-dialog/session-expired-dialog.component';

@Component({
  selector: 'app-print-dialog',
  templateUrl: './print-dialog.component.html',
  styleUrls: ['./print-dialog.component.css']
})
export class PrintDialogComponent implements OnInit {

  printForm = new FormGroup({
    title: new FormControl(''),
    pageSize: new FormControl('A4_LANDSCAPE'),
  });

  searchInProgress = false;

  constructor(
    public dialogRef: MatDialogRef<PrintDialogComponent>,
    private workspaceService: WorkspaceService,
    private printService: PrintService,
    public dialog: MatDialog
    ) { }

  ngOnInit() {
  }

  cancel() {
    this.dialogRef.close();
  }

  print() {
    const request: PrintRequest = {
      title: this.printForm.value.title,
      pageSize: this.printForm.value.pageSize,
      entities: this.workspaceService.cells.concat(this.workspaceService.annotations),
      minX: this.workspaceService.mapExtent[0],
      minY: this.workspaceService.mapExtent[1],
      maxX: this.workspaceService.mapExtent[2],
      maxY: this.workspaceService.mapExtent[3],
      backdrop: this.workspaceService.backdrop
    };

    // Show spinner and disable the form
    this.searchInProgress = true;
    this.printForm.disable();

    this.printService.print(request).subscribe(result => {
      this.searchInProgress = false;
      this.printForm.enable();
      this.saveBlob(result);
      this.dialogRef.close();
    }, err => {
      console.log(err);
      this.searchInProgress = false;
      this.printForm.enable();
      // Check for unauthorised
      if (err.status === 401) {
        this.dialog.open(SessionExpiredDialogComponent, {
          disableClose: true
        });
        this.dialogRef.close();
      }
    });
  }

  saveBlob(blob: Blob) {
    saveAs(blob, 'print.pdf');
  }

}
