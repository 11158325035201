import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'cmaps-ui';

  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer
  ) {
    // Register icons for use in the application
    iconRegistry.addSvgIcon('plus', sanitizer.bypassSecurityTrustResourceUrl('assets/images/entity-icons/plus.svg'));
    iconRegistry.addSvgIcon('cell', sanitizer.bypassSecurityTrustResourceUrl('assets/images/entity-icons/cell.svg'));
    iconRegistry.addSvgIcon('home', sanitizer.bypassSecurityTrustResourceUrl('assets/images/entity-icons/home.svg'));
    iconRegistry.addSvgIcon('sighting', sanitizer.bypassSecurityTrustResourceUrl('assets/images/entity-icons/sighting.svg'));
  }
}
