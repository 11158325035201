import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService, WorkspaceService } from '../_services';
import { interval, timer } from 'rxjs';
import { MiddlemanService } from '../_services';
import { MatDialog, MatSidenav } from '@angular/material';
import { PrintDialogComponent } from '../print-dialog/print-dialog.component';
import { LogoutDialogComponent } from '../logout-dialog/logout-dialog.component';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {
  title = 'CMAPS';
  mapHeight: number;
  @ViewChild('sidenav') sidenav: MatSidenav;

  constructor(
    private authService: AuthService,
    private middlemanService: MiddlemanService,
    public dialog: MatDialog,
    public workspaceService: WorkspaceService
  ) { }

  ngOnInit(): void {
    // Onload update the map size every 100ms for the first 2 seconds
    this.updateMapSize();
    const intervalSubscription = interval(100).subscribe(() => this.updateMapSize());

    // After 2 seconds, unsubscribe from the update loop
    timer(2000).subscribe(() => {
      intervalSubscription.unsubscribe();
    });

    // Listen for search trigger events so we can open the sidenav
    this.middlemanService.triggerSearchByLocation$.subscribe(event => {
      this.sidenav.open();
    });

    // Listen for sidenav open/close events so we can notify other components
    this.sidenav.openedStart.subscribe(() => {
      this.middlemanService.searchOpened();
    });
    this.sidenav.closedStart.subscribe(() => {
      this.middlemanService.searchClosed();
    });
  }

  /**
   * Update the map size, taking into account the toolbar height.
   */
  updateMapSize(): void {
    // Find the height of the toolbar
    const toolbarHeight = (document.querySelector('mat-toolbar') as HTMLElement).offsetHeight;
    // Make the map height the size of the window minus the toolbar for 100% height
    this.mapHeight = window.innerHeight - toolbarHeight;
  }

  /**
   * Display the print dialog.
   */
  showPrintDialog(event): void {
    this.dialog.open(PrintDialogComponent, {
      width: '250px'
    });
  }

  /**
   * Update the selected backdrop on the workspace.
   * @param backdrop the selected backdrop
   */
  setBackdrop(backdrop: string): void {
    this.workspaceService.setBackdrop(backdrop);
  }

  /**
   * Checks whether the current user is an admin user.
   */
  isAdminUser(): boolean {
    return this.authService.isAdminUser();
  }

  /**
   * Display a dialog asking the user if they would like to logout.
   */
  logout(): void {
    const dialogRef = this.dialog.open(LogoutDialogComponent, {
      disableClose: true
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.authService.logout();
      }
    });
  }
}
